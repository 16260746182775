<template>
  <TopBar v-if="$route.path !== '/login'" />
  <Sidebar v-if="$route.path !== '/login'" />
  <router-view />
  <Footer v-if="shouldShowFooter" />
  <ErrorMessage />
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Sidebar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import axios from "axios";

export default {
  axios,
  components: {
    TopBar,
    Sidebar,
    Footer,
    ErrorMessage,
  },
  computed: {
    shouldShowFooter() {
      const hiddenFooterRoutes = [
        "ToolsUtilities",
        "analytics",
        "docs",
        "filesManagement",
      ];
      return (
        this.$route.path !== "/login" &&
        !hiddenFooterRoutes.includes(this.$route.name)
      );
    },
  },
};
</script>
