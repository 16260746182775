<template>
  <footer
    class="flex items-center h-20 transition-all duration-300 justify-center"
    :class="{
      'ml-16 sm:ml-52 xl:ml-48': store.displayed && !isMobile,
    }"
  >
    <img
      src="@/assets/images/logo-dark.png"
      class="h-8 filter opacity-20"
      alt="Opus127 Logo"
    />
  </footer>
</template>

<script>
import { useStore } from "@/store";

export default {
  computed: {
    store() {
      return useStore();
    },
    isMobile() {
      return window.innerWidth <= 640;
    },
  },
};
</script>
