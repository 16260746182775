import { useStore } from "@/store";

export function handleError(error, context = "", customMessage = null) {
  const store = useStore();
  if (error instanceof TypeError) {
    handleTypeError(error, store, context);
  } else if (error.code) {
    handleNetworkErrors(error, store, context);
  } else if (typeof error?.response?.data?.error === "string") {
    handleStringError(error, store, context);
  } else if (error.response) {
    handleResponseErrors(error, store, context, customMessage);
  } else {
    store.handleMessages(true, "", error, "Erro inesperado");
  }
  store.errorControl(true);
}

function handleTypeError(error, store, context) {
  store.handleMessages(
    true,
    "Falha na comunicação com o servidor. Por favor, tente novamente mais tarde.",
    error,
    `Erro ao ${context}`
  );
}

function handleNetworkErrors(error, store, context) {
  switch (error.code) {
    case "ERR_NETWORK":
      store.handleMessages(
        false,
        "Falha de conexão. Verifique a conexão com o servidor do backend e tente novamente.",
        "",
        "Erro de conexão"
      );
      break;
    case "ERR_CONNECTION_REFUSED":
      store.handleMessages(
        false,
        "Conexão recusada. O servidor pode estar fora do ar ou inacessível.",
        "",
        "Erro de conexão"
      );
      break;
    case "ERR_BAD_RESPONSE":
      store.handleMessages(
        true,
        "Erro ao consultar dados.",
        error.response.data.error,
        `Erro ao ${context}`
      );
      break;
    case "ERR_TIMEOUT":
      store.handleMessages(
        false,
        "A conexão expirou. O servidor demorou muito para responder.",
        "",
        "Erro de conexão"
      );
      break;
    default:
      store.handleMessages(
        false,
        error.response.data.error,
        ``,
        `Erro ao ${context}`
      );
      break;
  }
}

function handleStringError(error, store, context) {
  const errorMessage = extractErrorMessage(error.response.data.error);
  store.handleMessages(false, errorMessage, "", `Erro ao ${context}`);
}

function handleResponseErrors(error, store, context, customMessage) {
  switch (error.response.status) {
    case 404:
      store.handleMessages(
        true,
        customMessage || "404 - O recurso solicitado não foi encontrado.",
        error.response.data.error,
        `Erro ao ${context}`
      );
      break;
    case 405:
      store.handleMessages(
        false,
        customMessage ||
          "Ação não permitida. Por favor, verifique se você está realizando a operação correta.",
        "",
        `Erro ao ${context}`
      );
      break;
    case 401:
      store.handleMessages(
        false,
        customMessage || "401 - Não autorizado. Verifique suas credenciais.",
        "",
        `Erro ao ${context}`
      );
      break;
    case 500:
      store.handleMessages(
        true,
        customMessage ||
          "500 - Erro interno do servidor. Tente novamente mais tarde.",
        error.response.data.error,
        `Erro ao ${context}`
      );
      break;
    default:
      store.handleMessages(
        true,
        `Erro ao ${context}`,
        error.response.data.error,
        `Erro ao ${context}`
      );
      break;
  }
}

function extractErrorMessage(error) {
  return error.includes("mssql:") ? error.split("mssql:")[1]?.trim() : error;
}
