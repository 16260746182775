import axios from "axios";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Plugins
import permissionsPlugin from "./plugins/permissionsPlugin";
import tabPermissionsPlugin from "./plugins/tabPermissionsPlugin";

// PrimeVue
import PrimeVue from "primevue/config";

import Accordion from "primevue/accordion";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";
import AccordionPanel from "primevue/accordionpanel";
import AutoComplete from "primevue/autocomplete";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Breadcrumb from "primevue/breadcrumb";
import Button from "primevue/button";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DatePicker from "primevue/datepicker";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import FileUpload from "primevue/fileupload";
import IconField from "primevue/iconfield";
import Image from "primevue/image";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputIcon from "primevue/inputicon";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import Popover from "primevue/popover";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import RadioButton from "primevue/radiobutton";
import Row from "primevue/row";
import Select from "primevue/select";
import SelectButton from "primevue/selectbutton";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import SplitButton from "primevue/splitbutton";
import Tab from "primevue/tab";
import TabList from "primevue/tablist";
import TabPanel from "primevue/tabpanel";
import TabPanels from "primevue/tabpanels";
import Tabs from "primevue/tabs";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";

import "primeicons/primeicons.css";
import "./assets/css/base.css";
import "./assets/css/global.css";
import "./assets/css/tailwind.css";
import Aura from "./presets/aura";

// const backendHost = "localhost";
const backendHost = "beta-opus127.nhtsi.com.br";
const backendPort = "8091"; // Produção
// const backendPort = "8099"; // Desenvolvimento

const environment = backendPort === "8099" ? "BETA" : null;

const app = createApp(App);

app.component("Button", Button);
app.component("InputText", InputText);
app.component("DataView", DataView);
app.component("Select", Select);
app.component("Dialog", Dialog);
app.component("Skeleton", Skeleton);
app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Checkbox", Checkbox);
app.component("Slider", Slider);
app.component("MultiSelect", MultiSelect);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Tag", Tag);
app.component("InputIcon", InputIcon);
app.component("IconField", IconField);
app.component("InputGroup", InputGroup);
app.component("InputGroupAddon", InputGroupAddon);
app.component("Paginator", Paginator);
app.component("Breadcrumb", Breadcrumb);
app.component("Password", Password);
app.component("Accordion", Accordion);
app.component("AccordionPanel", AccordionPanel);
app.component("AccordionHeader", AccordionHeader);
app.component("AccordionContent", AccordionContent);
app.component("Popover", Popover);
app.component("Tree", Tree);
app.component("Panel", Panel);
app.component("PanelMenu", PanelMenu);

app.component("Tab", Tab);
app.component("TabList", TabList);
app.component("Tabs", Tabs);
app.component("TabPanels", TabPanels);
app.component("TabPanel", TabPanel);

app.component("Image", Image);
app.component("FileUpload", FileUpload);
app.component("Badge", Badge);
app.component("Message", Message);
app.component("Chart", Chart);
app.component("DatePicker", DatePicker);
app.component("Textarea", Textarea);
app.component("Chip", Chip);
app.component("ProgressBar", ProgressBar);
app.component("InputMask", InputMask);
app.component("RadioButton", RadioButton);
app.component("InputNumber", InputNumber);
app.component("AutoComplete", AutoComplete);
app.component("Editor", Editor);
app.component("SplitButton", SplitButton);
app.component("SelectButton", SelectButton);
app.component("Menu", Menu);
app.component("Avatar", Avatar);

app.directive("tooltip", Tooltip);

app.use(PrimeVue, {
  unstyled: true,
  pt: Aura,
  options: {
    darkModeSelector: ".my-app-dark",
    cssLayer: {
      name: "primevue",
      order: "tailwind-base, Primevue, tailwind-utilities",
    },
  },
  locale: {
    passwordPrompt: "Insira a senha",
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    dayNames: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    emptyMessage: "Nenhum resultado encontrado",
    weak: "Fraca",
    medium: "Média",
    strong: "Forte",
  },
});

app.config.globalProperties.$axios = axios;

// Plugins
app.use(permissionsPlugin);
app.use(tabPermissionsPlugin);

app.use(ConfirmationService);
app.use(createPinia());
app.use(ToastService);
app.use(router);
app.config.globalProperties.$environment = environment;
app.config.globalProperties.$urlBackend = `http://${backendHost}:${backendPort}`;

// Change favicon based on environment, if it's beta or production
function setFavicon(faviconName) {
  let link =
    document.querySelector("link[rel~='icon']") ||
    document.createElement("link");
  link.rel = "icon";
  link.href = `${process.env.BASE_URL}${faviconName}`;
  if (!link.parentNode) document.head.appendChild(link);
}

setFavicon(environment ? "favicon-beta.ico" : "favicon.ico");

app.mount("#app");
